<template>
  <div class="flex items-center py-1">
    <div class="w-1/5 text-center rounded-lg bg-orange-200 py-1 px-2">
      <!-- <svg
        class="text-orange-700 stroke-current h-5 w-5 mr-2"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        viewBox="0 0 24 24"
      >
        <path
          d="M20.59 13.41l-7.17 7.17a2 2 0 01-2.83 0L2 12V2h10l8.59 8.59a2 2 0 010 2.82zM7 7h.01"
        />
      </svg> -->
      <span class="text-orange-700 text-sm">{{ label }}</span>
    </div>
    <p class="rounded-lg px-2 py-1">
      {{ value }}
    </p>
  </div>
</template>

<script>
export default {
  name: "EquipmentDetail",
  props: {
    label: String,
    value: String,
  },
};
</script>
